<template>
  <div>
    <el-card shadow="always" class="box-card">
      <el-tabs type="border-card">
        <el-tab-pane label="9N系列">
          <el-row>
            <el-col :span="3">产品图</el-col>
            <el-col :span="21">
              <img-upload :url="typeList[0].labelImg" @change="labelUrlChange($event, 0)"></img-upload>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">型号说明表</el-col>
            <el-col :span="21">
              <img-upload :url="typeList[0].infoImg"  @change="infoUrlChange($event, 0)"></img-upload>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">选型表</el-col>
            <el-col :span="21">
              <editor :init="init" v-model="typeList[0].tableContent"></editor>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="7N系列">
          <el-row>
            <el-col :span="3">产品图</el-col>
            <el-col :span="21">
              <img-upload :url="typeList[1].labelImg" @change="labelUrlChange($event, 1)"></img-upload>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">型号说明表</el-col>
            <el-col :span="21">
              <img-upload :url="typeList[1].infoImg"  @change="infoUrlChange($event, 1)"></img-upload>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">选型表</el-col>
            <el-col :span="21">
              <editor :init="init" v-model="typeList[1].tableContent"></editor>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="高压系列">
          <el-row>
            <el-col :span="3">产品图</el-col>
            <el-col :span="21">
              <img-upload :url="typeList[2].labelImg" @change="labelUrlChange($event, 2)"></img-upload>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">型号说明表</el-col>
            <el-col :span="21">
              <img-upload :url="typeList[2].infoImg"  @change="infoUrlChange($event, 2)"></img-upload>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">选型表</el-col>
            <el-col :span="21">
              <editor :init="init" v-model="typeList[2].tableContent"></editor>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="GC系列">
          <el-row>
            <el-col :span="3">产品图</el-col>
            <el-col :span="21">
              <img-upload :url="typeList[3].labelImg" @change="labelUrlChange($event, 3)"></img-upload>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">型号说明表</el-col>
            <el-col :span="21">
              <img-upload :url="typeList[3].infoImg"  @change="infoUrlChange($event, 3)"></img-upload>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">选型表</el-col>
            <el-col :span="21">
              <editor :init="init" v-model="typeList[3].tableContent"></editor>
            </el-col>
          </el-row>
        </el-tab-pane>
		
		<el-tab-pane label="过滤器系列">
		  <el-row>
		    <el-col :span="3">产品图</el-col>
		    <el-col :span="21">
		      <img-upload :url="typeList[4].labelImg" @change="labelUrlChange($event, 4)"></img-upload>
		    </el-col>
		  </el-row>
		  <el-row>
		    <el-col :span="3">型号说明表</el-col>
		    <el-col :span="21">
		      <img-upload :url="typeList[4].infoImg"  @change="infoUrlChange($event, 4)"></img-upload>
		    </el-col>
		  </el-row>
		  <el-row>
		    <el-col :span="3">选型表</el-col>
		    <el-col :span="21">
		      <editor :init="init" v-model="typeList[4].tableContent"></editor>
		    </el-col>
		  </el-row>
		</el-tab-pane>
		
      </el-tabs>
      <el-row type="flex" justify="center">
        <el-button size="medium" type="primary" style="min-width: 120px" @click="submit">保存</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/plugins/table'
import {getType, updateType} from "@/plugins/api/product";
import ImgUpload from "@/components/ImgUpload";

export default {
  name: 'spec',
  components: {
    ImgUpload,
    'editor': Editor
  },
  data() {
    return {
      init: {
        language_url: '/static/tinymce/lang/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/static/tinymce/skin/oxide',
        height: 560,
        branding: false,
        menubar: false,
        content_style: "p {margin: 0}",
        plugins: 'table',
        toolbar: 'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | table | alignleft aligncenter alignright alignjustify outdent indent | \
    styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
     image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs',
      },
      typeList: [
        [],[],[],[],[],
      ]
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    async submit() {
      await this.typeList.map(async x => {
        await updateType({
          id: x.id,
          label_img: x.labelImg,
          info_img: x.infoImg,
          table_content: x.tableContent,
          series_id: x.seriesId,
        })
      })
      this.getTypeList()
    },
    labelUrlChange(url, index) {
      this.typeList[index].labelImg = url;
    },
    infoUrlChange(url, index) {
      this.typeList[index].infoImg = url;
    },
    async getTypeList() {
      this.typeList = await getType();
    }
  },
  created() {
    this.getTypeList()
  }
};
</script>

<style scoped>
.el-row {
  margin-top: 20px;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
